import { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Layout from "../../../layout/logged_out/Layout";
import axios from '../../../api/axios';
import Session from '../../../api/session';
import ReCAPTCHA from 'react-google-recaptcha';
import { backendUrl, staticUrl } from "../../../config";
import { Set as LocalStorageSet } from "../../components/LocalStorage";

const Login = () => {

    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [pr1Success, setPr1Success] = useState(false);
    const [pr2Success, setPr2Success] = useState(false);
    const [pr3Success, setPr3Success] = useState(false);
    const [pr4Success, setPr4Success] = useState(false);
    const [pr5Success, setPr5Success] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [image, setImage] = useState('');

    // Form focus
    const formRef = useRef(null);
    useEffect(() => {
        formRef.current.focus();

        const getImage = async () => {

            const response = await axios.get(`${backendUrl}/w/content/media-library`);
            setImage(response?.data?.image?.relation_id);
    
        };

        getImage();

    }, []);

    useEffect(() => {
        // Whenever any of prXSuccess variables changes, update isDisabled
        setIsDisabled(!(pr1Success && pr2Success && pr3Success && pr4Success && pr5Success));
    }, [pr1Success, pr2Success, pr3Success, pr4Success, pr5Success]);

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);

        if ( (event.target.value).length > 8 && (event.target.value).length < 25 ) {
            setPr1Success(true);
        } else {
            setPr1Success(false);
        }

        if ( (/[0-9]/).test(event.target.value) ) {
            setPr2Success(true);
        } else {
            setPr2Success(false);
        }

        if ( (/[a-z]/).test(event.target.value) ) {
            setPr3Success(true);
        } else {
            setPr3Success(false);
        }

        if ( (/[A-Z]/).test(event.target.value) ) {
            setPr4Success(true);
        } else {
            setPr4Success(false);
        }
        
        if ( (/[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/).test(event.target.value) ) {
            setPr5Success(true);
        } else {
            setPr5Success(false);
        }

    };

    // reCAPTCHA
    const recaptchaRef = useRef();
    const executeReCaptcha = () => {
        return new Promise((resolve, reject) => {
            recaptchaRef.current.execute("submit_form")
            .then((token) => {
                resolve(token);
            })
            .catch((error) => {
                reject(error);
            });
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            // Get reCAPTCHA token
            const token = await executeReCaptcha();

            const data = {
                token: token,
                username: username,
                password: password,
            };
      
            try {
    
                const state = {
                    username: username
                };
    
                const response = await axios.post(`${backendUrl}/w/login`, data);
    
                if ( response?.status === 200 && response?.data.authenticator ) {
                    navigate(`/two-factor`, { state });
                } else if ( response?.status === 200 && response?.data.challenge ) {
                    navigate(`/challenge`, { state });
                } else if ( response?.status === 200 ) {
                    LocalStorageSet(response?.data?.profile_data);
                    if ( response?.data?.profile_data?.is_admin ) {
                        navigate(`/admin/products/list`);
                    } else {
                        navigate(`/image-library`);
                    }
                }
    
            } catch (error) {

                if ( !error?.response ) {
                    window.showToast('WCL19900');
                } else {
                    window.showToast('WCL19901');
                }
    
                formRef.current.focus();

            }


        } catch (error) {
            window.showToast('WCL20398');
            return console.error('reCAPTCHA validation failed:', error);
        }

    };

    return (
        
        <Layout title='Log In'>

            <Session />

                <div className="mt-[80px] xl:mt-[96px] max-w-screen-2xl mx-auto">
                    <div className="xl:grid grid-cols-2 gap-16">
                        <div className="hidden xl:block p-8">
                            <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-[50vw] max-w-[730px]" alt="" />
                        </div>
                        <div className="px-4 py-4 md:py-8 md:px-8">
                            <h1 className="text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light">Log In</h1>
                            <div className="w-full text-kb-400 pb-4 leading-8">
                                <div className="flex items-center justify-center">
                                    <form className="w-full space-y-6 md:space-y-8" onSubmit={handleSubmit}>
                                        { process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY && ( <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} size="invisible" ref={recaptchaRef} /> )}
                                        <div>
                                            <p className="text-sm text-kb-400">
                                                Don't have an account? <Link to="/image-library-application" className="font-semibold text-kb-300 hover:underline ml-1">Sign Up</Link>
                                            </p>
                                        </div>
                                        <div>
                                            <input type="email" name="username" id="username" ref={formRef} onChange={handleUsernameChange} autoComplete="hostney-username" className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Username or email address" required="" />
                                        </div>
                                        <div>
                                            <input type="password" name="password" id="password" onChange={handlePasswordChange} placeholder="Password" autoComplete="off" className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" required="" />
                                        </div>
                                        <button className="w-full text-white bg-kb-300 hover:bg-kb-600 focus:ring-4 focus:outline-none focus:ring-kb-300 font-medium rounded text-sm px-5 text-center py-3.5 disabled:bg-kb-500 disabled:text-kb-400" disabled={isDisabled}>Continue</button>
                                        <div className="flex items-center">
                                            <div className="w-full h-0.5 bg-gray-200"></div>
                                            <div className="px-5 text-center text-gray-500">or</div>
                                            <div className="w-full h-0.5 bg-gray-200"></div>
                                        </div>

                                        <div className="flex items-center justify-center">
                                        <Link to="/recovery" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Forgot your password?</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div className="xl:hidden">
                <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-full p-4 md:p-8" alt="" />
            </div>


        </Layout>

     );
}
 
export default Login;