import Layout from "../../../layout/logged_out/Layout";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import generateRandomKey from "../../components/GenerateRandomKey";
import Loading from '../../components/Loading';
import { backendUrl, staticUrl } from "../../../config";

const WhereToBuy = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [resultsArray, setResultsArray] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [dealersByState, setDealersByState] = useState({});
    const [dealersByCountry, setDealersByCountry] = useState([]);
    const [showSearch, setShowSearch] = useState(true);
    const [showroomsByState, setShowroomsByState] = useState([]);
    const [showroomsByCountry, setShowroomsByCountry] = useState([]);
    const [image, setImage] = useState('');
    const [zip, setZip] = useState('');

    useEffect(() => {

        const getImage = async () => {

            const response = await axios.get(`${backendUrl}/w/content/where-to-buy`);
            setImage(response?.data?.image?.relation_id);

        };

        getImage();

    }, []);

    const [found, setFound] = useState(0);
    const [range, setRange] = useState(0);

    const zipRef = useRef(null);
    const rangeRef = useRef(null);
    
    const groupDealersByState = (dealers) => {
        // Group dealers by state
        const dealersByState = {};
        dealers.forEach(dealer => {
            if (!dealersByState[dealer?.state]) {
                dealersByState[dealer?.state] = [];
            }
            dealersByState[dealer?.state].push(dealer);
        });
        return dealersByState;
    };

    const groupDealersByCountry = (dealers) => {
        // Group dealers by country
        const dealersByCountry = {};
        dealers.forEach(dealer => {
            if (!dealersByCountry[dealer?.country]) {
                dealersByCountry[dealer?.country] = [];
            }
            dealersByCountry[dealer?.country].push(dealer);
        });
        return dealersByCountry;
    };

    const locateDealers = async () => {
        try {

            if ( !zip || !rangeRef.current.value ) return window.showToast('WCL20450');

            setDealersByState({});
            setDealersByCountry({});
            setIsDisabled(true);
            setIsLoading(true);
            setFound(0);
            setResultsArray([]);
            setRange(rangeRef.current.value);

            const data = {
                zip: zip,
                range: rangeRef.current.value
            };

            const response = await axios.post(`${backendUrl}/w/locate-dealers`, data);

            setFound((response.data && response.data.dealers) ? response.data.dealers.length : 0);

            const _results = [];

            const buildResults = async () => {
                if ( response.data && response.data.dealers ) {
                    const promises = response.data.dealers.map((dealer, index) => {
                        
                        const address = `${dealer?.address}, ${dealer?.city}, ${dealer?.state} ${dealer?.zip_code}`;
                        const encodedAddress = encodeURIComponent(address);
                        const googleMapsUrl = `https://maps.google.com/maps?saddr=&daddr=${encodedAddress}`;

                        const state = (dealer?.state) ? dealer?.state.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase()) : '';

                        _results.push(
                            <div key={generateRandomKey()} className="space-y-1 text-sm p-4 border border-kb-400/20 rounded bg-gray-50">
                                <div className="font-bold text-kb-300 text-lg">{(dealer?.name) ? dealer?.name : ''}</div>
                                <div>{(dealer?.address) ? dealer?.address : ''}</div>
                                <div>{(dealer?.city) ? dealer?.city : ''}, {state} {(dealer?.zip_code) ? dealer?.zip_code : ''}</div>
                                <div>{(dealer?.phone) ? dealer?.phone : ''}</div>
                                <div>Approximately: {(dealer?.distance) ? dealer?.distance.toFixed(2) : ''} miles</div>
                                <div className="space-x-3"><a href={googleMapsUrl} className="text-kb-300 hover:underline underline-offset-4" target="_blank">Google Map</a>{dealer?.url?.match(/[a-z]+/) ? <> <a href={dealer?.url} className="text-kb-300 hover:underline underline-offset-4" target="_blank">Website</a></> : ''}</div>
                            </div>
                        );
                    });

                    await Promise.all(promises);
                }
            };

            await buildResults();
            setResultsArray(_results);
            console.log(_results)
            setIsLoading(false);
            setTimeout(() => {
                setIsDisabled(false);
            }, 3000);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setTimeout(() => {
                setIsDisabled(false);
            }, 3000);
        }
    };

    const showShowrooms = async () => {
        try {

            setIsLoading(true);
            setShowSearch(false);

            const data = {
                all: 'showrooms'
            };

            setDealersByState([]);
            setDealersByCountry([]);

            const response = await axios.post(`${backendUrl}/w/locate-dealers`, data);
            const dealersGroupedByState = groupDealersByState(response.data.dealers);
    
            // Sort the state names in ascending order
            const sortedStates = Object.keys(dealersGroupedByState).sort();
    
            // Sort the dealers within each state by name in ascending order
            const sortedDealersByState = sortedStates.reduce((acc, state) => {
                acc[state] = dealersGroupedByState[state].sort((a, b) => a.name.localeCompare(b.name));
                return acc;
            }, {});
    
            const nonUSDealersGroupedByCountry = groupDealersByCountry(response.data.international);

            // Sort the country names in ascending order
            const sortedCountries = Object.keys(nonUSDealersGroupedByCountry).sort();

            // Create an array of objects with country names and dealers
            const dealersByCountryArray = sortedCountries.map(country => ({
                country,
                dealers: nonUSDealersGroupedByCountry[country].sort((a, b) => a.name.localeCompare(b.name))
            }));

            setResultsArray([]);
            setRange(0);
            setShowroomsByState(sortedDealersByState);
            setShowroomsByCountry(dealersByCountryArray);
            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setTimeout(() => {
                setIsDisabled(false);
            }, 3000);
        }
    };

    const showUSDealers = async () => {
        try {

            setIsLoading(true);
            setShowSearch(false);

            const data = {
                us: 'show'
            };

            setDealersByCountry([]);
            setShowroomsByCountry([]);
            setShowroomsByState([]);

            const response = await axios.post(`${backendUrl}/w/locate-dealers`, data);
            const dealersGroupedByState = groupDealersByState(response.data.dealers);
    
            // Sort the state names in ascending order
            const sortedStates = Object.keys(dealersGroupedByState).sort();
    
            // Sort the dealers within each state by name in ascending order
            const sortedDealersByState = sortedStates.reduce((acc, state) => {
                acc[state] = dealersGroupedByState[state].sort((a, b) => a.name.localeCompare(b.name));
                return acc;
            }, {});
    
            setResultsArray([]);
            setRange(0);
            setDealersByState(sortedDealersByState);
            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setTimeout(() => {
                setIsDisabled(false);
            }, 3000);
        }
    };

    const showNonUSDealers = async () => {
        try {

            setIsLoading(true);
            setShowSearch(false);

            const data = {
                nonus: 'show'
            };

            setDealersByState([]);
            setShowroomsByCountry([]);
            setShowroomsByState([]);

            const response = await axios.post(`${backendUrl}/w/locate-dealers`, data);
            const nonUSDealersGroupedByCountry = groupDealersByCountry(response.data.dealers);

            // Sort the country names in ascending order
            const sortedCountries = Object.keys(nonUSDealersGroupedByCountry).sort();

            // Create an array of objects with country names and dealers
            const dealersByCountryArray = sortedCountries.map(country => ({
                country,
                dealers: nonUSDealersGroupedByCountry[country].sort((a, b) => a.name.localeCompare(b.name))
            }));

            setDealersByState({});
            setResultsArray([]);
            setRange(0);
            setDealersByCountry(dealersByCountryArray);
            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setTimeout(() => {
                setIsDisabled(false);
            }, 3000);
        }
    };
    
    const handleZip = (e) => {
        
        setZip(e.target.value.slice(0, 5));

        if (e.target.value.length > 5) {
            e.target.value = e.target.value.slice(0, 5);
        }
    };

    if ( isLoading ) {
        return <Loading />;
    }

    return ( 
        <Layout title='Dealer Locator'>

            <div className="mt-[80px] xl:mt-[96px] mx-auto text-kb-400">
                <div className="flex">
                    <div className="hidden xl:block p-8">
                        <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-[40vw] max-w-[730px]" alt="" />
                    </div>
                    <div className="px-4 py-4 md:py-8 md:px-8">
                        <h1 className="text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light pb-10">Dealer Locator</h1>
                        { showSearch && (
                            <div className="py-7">Please enter your zip code to locate the nearest Kingsley Bate dealer within the U.S.:</div>
                        ) }
                        <div className="space-y-10">

                            <div className="space-y-10">
                                { showSearch && (
                                    <>
                                        <div className="md:flex md:space-x-6 space-y-6 md:space-y-0">
                                            <div className="flex space-x-4 items-center">
                                                <div>Distance:</div>
                                                <select ref={rangeRef} defaultValue='10' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300">
                                                    <option value='10'>10 Miles</option>
                                                    <option value='25'>25 Miles</option>
                                                    <option value='50'>50 Miles</option>
                                                    <option value='75'>75 Miles</option>
                                                    <option value='100'>100 Miles</option>
                                                    <option value='150'>150 Miles</option>
                                                </select>
                                            </div>
                                            <div className="flex items-center space-x-4 whitespace-nowrap">
                                                <div>Zip Code:</div>
                                                <div>
                                                    <div className="relative lg:w-52">
                                                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                                            <svg className="w-5 h-5 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" >
                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                            </svg>
                                                        </div>
                                                        <input ref={zipRef} onInput={handleZip} defaultValue={zip} placeholder="12345" type="number" className="no-spinner bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full pl-10" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button disabled={isDisabled} onClick={(e) => { e.preventDefault(); locateDealers()}} className="text-white bg-kb-300 hover:bg-kb-600 rounded px-4 py-[5px] mr-2 cursor-pointer disabled:bg-kb-300/50 disabled:cursor-default">SEARCH DEALERS</button>
                                        </div>
                                    </>
                                ) }

                                <div className="space-y-1">
                                    <div><button className="hover:text-kb-300" onClick={(e) => { e.preventDefault(); showUSDealers() }}>View All U.S. Dealers</button></div>
                                    <div><button className="hover:text-kb-300" onClick={(e) => { e.preventDefault(); showNonUSDealers() }}>View All International Dealers</button></div>
                                    <div><button className="hover:text-kb-300" onClick={(e) => { e.preventDefault(); showShowrooms() }}>View Showrooms To The Trade</button></div>
                                </div>
                            </div>

                            { range > 0 && (
                                <div className="py-4">{found} dealer{(found > 1) ? 's' : ''} {(found > 1) ? 'were' : 'was'} found within {range} miles</div>
                            ) }

                            { resultsArray.length > 0 && (
                                <div className="w-full space-y-4">
                                    {resultsArray}
                                </div>
                            ) }

                            {Object.keys(showroomsByState).length > 0 && (
                                <div className="pt-10 space-y-6">
                                    <div className="uppercase text-xl text-kb-300 font-bold">Showrooms to the Trade</div>
                                    {Object.entries(showroomsByState).map(([state, dealers]) => (
                                        <div key={generateRandomKey()} className="space-y-4">
                                            <h3 className="uppercase">{state}</h3>
                                            <ul className="space-y-2">
                                                {dealers.map(dealer => {

                                                    const address = `${dealer?.address}, ${dealer?.city}, ${dealer?.state} ${dealer?.zip_code}`;
                                                    const encodedAddress = encodeURIComponent(address);
                                                    const googleMapsUrl = `https://maps.google.com/maps?saddr=&daddr=${encodedAddress}`;

                                                    return (
                                                        <li key={generateRandomKey()}>
                                                            <strong>{dealer?.name}</strong> - {dealer?.address}, {dealer?.city}{(dealer?.zip_code?.match(/[a-z0-9]+/)) ? `, ${dealer?.zip_code}` : ''} | <a href={googleMapsUrl} className="hover:text-kb-300" target="_blank">Google Map</a> {dealer?.url?.match(/[a-z]+/) ? <> | <a href={(dealer?.url.startsWith('http://') || dealer?.url.startsWith('https://')) ? dealer?.url : `https://${dealer?.url}`} className="hover:text-kb-300" target="_blank" rel="noopener noreferrer">Website</a></> : ''}
                                                        </li>
                                                    );

                                                })}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            ) }

                            {Object.keys(dealersByState).length > 0 && (
                                <div className="pt-10 space-y-6">
                                    <div className="uppercase text-xl text-kb-300 font-bold">Authorized Dealers</div>
                                    {Object.entries(dealersByState).map(([state, dealers]) => (
                                        <div key={generateRandomKey()} className="space-y-4">
                                            <h3 className="uppercase">{state}</h3>
                                            <ul className="space-y-2">
                                                {dealers.map(dealer => {

                                                    const address = `${dealer?.address}, ${dealer?.city}, ${dealer?.state} ${dealer?.zip_code}`;
                                                    const encodedAddress = encodeURIComponent(address);
                                                    const googleMapsUrl = `https://maps.google.com/maps?saddr=&daddr=${encodedAddress}`;

                                                    return (
                                                        <li key={generateRandomKey()}>
                                                            <strong>{dealer?.name}</strong> - {dealer?.address}, {dealer?.city}{(dealer?.zip_code?.match(/[a-z0-9]+/)) ? `, ${dealer?.zip_code}` : ''} | <a href={googleMapsUrl} className="hover:text-kb-300" target="_blank">Google Map</a> {dealer?.url?.match(/[a-z]+/) ? <> | <a href={(dealer?.url.startsWith('http://') || dealer?.url.startsWith('https://')) ? dealer?.url : `https://${dealer?.url}`} className="hover:text-kb-300" target="_blank" rel="noopener noreferrer">Website</a></> : ''}
                                                        </li>
                                                    );

                                                })}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            ) }

                            {dealersByCountry.length > 0 && (
                                <div className="pt-10 space-y-6">
                                    <div className="uppercase text-xl text-kb-300 font-bold">Authorized Dealers</div>
                                    {dealersByCountry.map(({ country, dealers }) => (
                                        <div key={generateRandomKey()} className="space-y-4">
                                            <h3 className="uppercase">{country}</h3>
                                            <ul className="space-y-2">
                                                {dealers.map(dealer => {
                                                    return (
                                                        <li key={generateRandomKey()}>
                                                            <strong>{dealer?.name}</strong> - {dealer?.address}, {dealer?.city}{(dealer?.zip_code?.match(/[a-z0-9]+/)) ? `, ${dealer?.zip_code}` : ''} {dealer?.url?.match(/[a-z]+/) ? <> | <a href={(dealer?.url.startsWith('http://') || dealer?.url.startsWith('https://')) ? dealer?.url : `https://${dealer?.url}`} className="hover:text-kb-300" target="_blank" rel="noopener noreferrer">Website</a></> : ''}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {showroomsByCountry.length > 0 && (
                                <div className="pt-10 space-y-6">
                                    {/* <div className="uppercase text-xl text-kb-300 font-bold">Authorized Dealers</div> */}
                                    {showroomsByCountry.map(({ country, dealers }) => (
                                        <div key={generateRandomKey()} className="space-y-4">
                                            <h3 className="uppercase">{country}</h3>
                                            <ul className="space-y-2">
                                                {dealers.map(dealer => {
                                                    return (
                                                        <li key={generateRandomKey()}>
                                                            <strong>{dealer?.name}</strong> - {dealer?.address}, {dealer?.city}{dealer?.zip_code} {dealer?.url?.match(/[a-z]+/) ? <> | <a href={(dealer?.url.startsWith('http://') || dealer?.url.startsWith('https://')) ? dealer?.url : `https://${dealer?.url}`} className="hover:text-kb-300" target="_blank" rel="noopener noreferrer">Website</a></> : ''}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <div className="xl:hidden pb-6">
                <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-full p-4 md:p-8" alt="" />
            </div>

        </Layout>
    );
}
 
export default WhereToBuy;
