import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Logo } from "../../components/Logo";
import Title from '../../components/Title';
import AuthCTA from '../../components/AuthCTA';
import axios from '../../../api/axios';
import { Set as LocalStorageSet } from "../../components/LocalStorage";
import { backendUrl } from "../../../config";

const Challenge = () => {

    const CHALLENGE_REGEX = /^[0-9]{6}$/;
    const navigate = useNavigate();
    const [challenge, setChallenge] = useState('');
    const location = useLocation();
    const username = location.state?.username;
    let fl = '';

    // Form focus
    const formRef = useRef(null);
    useEffect(() => {
        formRef.current.focus();
    }, []);

    useEffect(() => {
        if ( challenge === '' ) {
            formRef.current.focus();
        }
    }, [challenge]);

    useEffect(() => {
        if ( typeof username !== 'string' || username.length === 0 ) {
            try {
                navigate('/login');
            } catch (error) {
                console.error('Navigation error:', error);
            }
        }
    }, [navigate, username]);

    if ( typeof username === 'string' && username.length > 0 ) fl = username.substring(0, 1);

    const handleChallengeChange = (event) => {
        const input = event.target.value;

        // Check if the input length is not greater than 6 characters
        if (input.length <= 6) {
            setChallenge(input);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if ( !CHALLENGE_REGEX.test(challenge) ) {
            window.showToast('WCL20401');
            return;
        }

        const data = {
            challenge: challenge
        };

        try {

            // Send a POST request using Axios
            const response = await axios.post(`${backendUrl}/w/challenge`, data);

            if ( response?.status === 200 ) {
                LocalStorageSet(response?.data?.profile_data);
                if ( response?.data?.profile_data?.is_admin ) {
                    navigate(`/admin/products/list`);
                } else {
                    navigate(`/image-library`);
                }
            }

        } catch (error) {

            if ( !error?.response ) {
                window.showToast('WCL19900');
                setChallenge('');
            } else if ( error.response?.status === 400 ) {
                window.showToast('WCL20401');
                setChallenge('');
            } else {
                navigate('/login');
            }

        }
    };

    return ( 

        <section className="select-none">

            <Title set="Challenge" />

            <div className="grid lg:h-screen lg:grid-cols-2">
                <AuthCTA />
                <div className="flex items-center justify-center px-4 py-6 lg:py-0 sm:px-0">
                    <form className="w-full max-w-md space-y-6 md:space-y-8 xl:max-w-xl" onSubmit={handleSubmit}>
                        <div>
                            <div className="flex justify-center items-center text-2xl font-semibold text-white my-8">
                                <Link to="/"><Logo size="medium" /></Link>
                            </div>
                            <div className="flex justify-center items-center text-2xl font-bold text-gray-900 my-4 uppercase">Check Your Mailbox</div>
                            <p className="flex justify-center items-center text-sm text-gray-500">We have sent you a challenge code.</p>
                        </div>
                        <div className="flex justify-center">
                            <div className="flex items-center text-sm p-2 rounded-full border border-gray-300 bg-gray-50">
                                <div className="flex items-center justify-center uppercase rounded-full bg-gray-300 mr-2 w-[32px] h-[32px] pl-[1px] pt-[1px]">
                                    <div className="font-extrabold text-lg text-gray-900">{fl}</div>
                                </div>
                                <div className="text-gray-900 mr-1">{username}</div>
                            </div>
                        </div>
                        <div>
                            <input type="text" name="challenge" value={challenge} onChange={handleChallengeChange} ref={formRef} maxLength="6" className="bg-gray-50 border border-kb-500 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-3.5" placeholder="Challenge Code" required />
                        </div>
                        <button type="submit" className="w-full text-white bg-kb-300 hover:bg-kb-600 focus:ring-4 focus:outline-none focus:ring-kb-300 font-medium rounded text-sm px-5 text-center py-3.5 disabled:bg-kb-500 disabled:text-kb-400">Continue</button>
                        <div className="flex items-center justify-center">
                            <Link to="/login" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Return to Log In</Link>
                        </div>
                    </form>
                </div>
            </div>

        </section>

     );
}
 
export default Challenge;